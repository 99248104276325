
  <nav class="desktop-menu" *ngIf="!isMobile">
    <a [routerLink]="'/'"  [attr.aria-label]="text.topheader.arialabel" role="button" tabindex="0">
      <img class="desktop-logo" alt="Logo" src="assets/logo-100.webp" />
    </a>
    <div *ngFor="let category of categories" routerDirection="root" (click)="openItem(category)" lines="none" detail="false" routerLinkActive="selected">
      {{ category.name}}
    </div>
    <ion-toolbar class="desktop" *ngIf="!isMobile">
      <ion-searchbar
      [debounce]="500"
      placeholder="{{text.topheader.placeholderText}}"
      (ionInput)="searchItem($event)"
      (ionClear)="clearSearchbar()"
      value="{{searchbarInput}}">
      </ion-searchbar>
    </ion-toolbar>
  </nav>

  <nav class="mobile-menu" *ngIf="isMobile">
    <div *ngIf="webpageService.showSearchBar" class="mobile-searchbar-container">
      <ion-searchbar
      class="mobile"
      search-icon="null"
      [debounce]="500"
      placeholder="{{text.topheader.placeholderText}}"
      (ionInput)="searchItem($event)"
      (ionClear)="clearSearchbar()"
      value="{{searchbarInput}}">
      </ion-searchbar>
      <ion-icon [attr.aria-label]="text.topheader.ariaButtonCloseSearch" role="button" tabindex="0"
      class="close-searchbar-icon"
      name="search"
      src="assets/icons-custom/arrow-back-outline.svg"
      (click)="toggleSearch()">
      </ion-icon>
    </div>
    <div class="mobile-logo-container">
      <a [routerLink]="'/'"  [attr.aria-label]="text.topheader.arialabel" role="button" tabindex="0">
        <img class="mobile-logo" alt="Logo" src="assets/logo-100.webp" />
      </a>
    </div>
    <div class="mobile-slogan"> {{text.topheader.websiteName}} </div>
    <div class="menu-icons">
      <ion-button *ngIf="isMobile" fill="clear" class="search-icon" (click)="toggleSearch()" [attr.aria-label]="text.topheader.ariaButtonSearch" role="button" tabindex="0">
        <ion-icon src="assets/icons-custom/search-circle-outline.svg"></ion-icon>
      </ion-button>
      <ion-button *ngIf="isMobile" fill="clear" class="menu-icon" (click)="toggleMenu()" [attr.aria-label]="text.topheader.ariaButtonMenu" role="button" tabindex="0">
        <ion-icon src="assets/icons-custom/menu-outline.svg"></ion-icon>
      </ion-button>
    </div>
  </nav>

  <div class="side-menu-overlay" *ngIf="menuOpen" (click)="toggleMenu()"></div>

  <nav class="side-menu" [ngClass]="{'open': menuOpen}">
    <ion-content style="height: 100%;">
      <ion-button fill="clear" class="side-menu-close-button" (click)="toggleMenu()" [attr.aria-label]="text.topheader.ariaButtonCloseMenu" role="button" tabindex="0">
        <ion-icon src="assets/icons-custom/close-outline.svg"></ion-icon>
      </ion-button>
      <ion-list class="side-menu-list" role="list">
        <ion-list-header class="side-menu-list-header" role="listitem">
          <ion-label>Menu</ion-label>
        </ion-list-header>
        <div role="listitem" *ngFor="let category of categories; let i = index;" [ngStyle]="{'background': getColorForCategory(i)}">
          <a (click)="goTo($event, category.path)" role="link">
            <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon> <span>{{ category.name }}</span>
          </a>
        </div>
        <div role="listitem">
          <a role="link" (click)="goTo($event, text.routes.contact)">
              <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon>  {{text.footer.contact}}
          </a>
          <a role="link" (click)="goTo($event, text.routes.privacyPolicy)">
            <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon>  {{text.footer.privacyPolicy}}
          </a>
        </div>
      </ion-list>
    </ion-content>
  </nav>
