import { Component, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { WebpageService } from '../../services/web.service';
import { TextService } from 'src/services/text.service';
import { Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';


@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.page.html',
  styleUrls: ['./privacy-policy.page.scss'],
})
export class PrivacyPolicyPage {

  @ViewChildren('subheadingRef', { read: ElementRef }) subheadingRefs: QueryList<ElementRef> | any;
  webpage: any;
  searchbarResults$: Subscription | undefined;
  searchbarItems: any[] = [];
  searchbarInputLength = 0;
  privacyPolicy: any;

  constructor(
    private title: Title,
    private meta: Meta,
    private webpageService: WebpageService,
    public platform: Platform,
    public text: TextService
  ) {

    this.privacyPolicy = this.text.privacyPolicy;

    this.setMetaTags();
    this.webpageService.initializeSearchbar();
  }

  ionViewDidEnter() {
    this.webpageService.clearSearchbarInput();
    this.searchbarResults$ = this.webpageService.getSearchbarResults().subscribe(results => {
      this.searchbarItems = results.items;
      this.searchbarItems.forEach((searchbarItem:any) => {
        let image = this.webpageService.transformFilename(searchbarItem.i);
        searchbarItem.img1x = `assets/images/${image}`;
      });
      this.searchbarInputLength = results.searchbarInputLength;
    });
  }

  ionViewWillLeave() {
    if (this.searchbarInputLength > 0 ) { this.webpageService.triggerClear(); }
    this.searchbarResults$?.unsubscribe();
  }

  scrollToElement(subheadingRefName: string) {
    const elementToScroll = this.subheadingRefs.find((ref:any) => ref.nativeElement.id === subheadingRefName);
    if (elementToScroll) {
      elementToScroll.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  setMetaTags() {
    this.title.setTitle(this.text.privacyPolicy.pageTitle);
    this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
  }

}
