<ion-list class="default-list ion-no-padding" *ngIf="searchbarInputLength > 0" role="list">
  <ion-row class="ion-no-padding">
    <ion-col class="ion-no-padding" role="listitem" *ngFor="let searchbarItem of searchbarItems | slice:0:12" size-xs="6" size-sm="6" size-md="4" size-lg="4">
      <div class="sl-item" (click)="openSearchbarItem(searchbarItem)">
        <div class="default-image-container">
          <ion-skeleton-text *ngIf="!searchbarItem.loaded" [animated]="true"></ion-skeleton-text>
          <ion-img (ionImgDidLoad)="searchbarItem.loaded = true" alt="{{searchbarItem.n}}" src="{{searchbarItem?.img1x}}" [ngClass]="{'fadeIn': searchbarItem.loaded, 'article': searchbarItem.t === 'A'}"></ion-img>
        </div>
        <span class="sl-name">{{searchbarItem.n}}</span>
      </div>
    </ion-col>
  </ion-row>
</ion-list>