import { Injectable, makeStateKey, TransferState, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import Fuse from 'fuse.js';
import { BehaviorSubject } from 'rxjs';
import { firstValueFrom, Observable } from 'rxjs';
import webpage from './../assets/webpage.json';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class WebpageService {

  webpage:any = webpage;
  items: any;
  //items = items;
  myFuseOptions = {
    caseSensitive: false,
    keys: ["n", "e", "k"],
    shouldSort: true,
  };
  myFuse: any;
  searchResults = new BehaviorSubject<any>({items: [], searchbarInputLength: 0});
  isBrowser: any;
  clearSearchSubject = new BehaviorSubject<boolean>(false);
  rootCategoriesSubject = new BehaviorSubject<any>([]);
  rootCategories:any;
  showSearchBar: boolean = false;
  FIREBASE_WEBPANEL_FUNCTIONS_URL = "https://us-east1-webpanel-admin.cloudfunctions.net/myFunctions/"

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) platformId: Object,
    private transferState: TransferState,
    private title: Title,
    private router: Router,
    private meta: Meta) {

    this.isBrowser = isPlatformBrowser(platformId);
  }

  triggerClear() { this.clearSearchSubject.next(true); }

  clearCompleted() { this.clearSearchSubject.next(false); }

  search(searchbarInput:any) {
    const items = this.myFuse.search(searchbarInput).map((i:any) => i.item);
    this.searchResults.next({items, searchbarInputLength: items.length });
  }

  getDataFromJSONFile(folder:any, slug:any) { return firstValueFrom(this.http.get(`/assets/${folder}/${slug}.json`)); }

  getWebpageFileJSON() { return firstValueFrom(this.http.get(`/assets/webpage.json`)); }


  initializeSearchbar(){
    if(this.isBrowser) {
      if(!this.myFuse) {

        this.http.get(`/assets/searchbars/searchbar.json`).subscribe((data) => {
          this.items = data;
          const myFuseIndex = Fuse.createIndex(this.myFuseOptions.keys, this.items);
          this.myFuse = new Fuse(this.items, this.myFuseOptions, myFuseIndex);
        });
      }
    }
  }

  transformFilename(input: string): string { return input.replace(/(\.(jpg|webp))$/, '-1x$1'); }

  sendMessageViaContactForm(data: any) {
    const url = this.FIREBASE_WEBPANEL_FUNCTIONS_URL + 'sendMessageViaContactForm';
    return this.http.post(url, data);
  }

  clearSearchbarInput() { this.searchResults.next({items: [], searchbarInputLength: 0 }); }

  async openSearchbarItem(item: any) {
    const path = item.p;
    const navigationExtras: NavigationExtras = { state: { slug: item.s }};
    this.clearSearchbarInput();
    this.router.navigate([path], navigationExtras);
    this.triggerClear();
  }

  getSearchbarData (searchbarInput: any) { return this.myFuse.search(searchbarInput, {limit: 12}).map((i:any) => i.item); }

  async getItem(slug:any, folder:any) {
    if(this.isBrowser) {
      if (this.transferState.hasKey(slug)) {
        let item = this.transferState.get(slug, null);
        if (folder === 'roots') { this.rootCategoriesSubject.next(item);}
        //this.transferState.remove(slug); // Optional: remove the key after retrieving it
        return item;
      } else {
        let item = await this.getDataFromJSONFile(folder, slug);
        if (folder === 'roots') { this.rootCategoriesSubject.next(item);}
        return item;
      }
    }
    else {
      let fs = require('fs');
      let item = JSON.parse(fs.readFileSync(`dist/functions/browser/assets/${folder}/${slug}.json`, 'utf8'));
      const transferStateKey = makeStateKey<any>(slug);
      this.transferState.set(transferStateKey, item);
      return item;
    }
  }

  async getWebpageData() {
    const slug:any = `webpage`;
    if (this.isBrowser) {
      if (this.transferState.hasKey(slug)) {
        let webpage:any = this.transferState.get(slug, null);
        return webpage;
      } else {

        let webpage:any = await this.getWebpageFileJSON();
        return webpage;
      }
    }
    else {
      let fs = require('fs');
      let webpage:any = JSON.parse(fs.readFileSync(`dist/functions/browser/assets/webpage.json`, 'utf8'));
      const transferStateKey = makeStateKey<any>(slug);
      this.transferState.set(transferStateKey, webpage);
      return webpage;
    }
  }

  getRootCategories() { return this.rootCategoriesSubject.asObservable().pipe();  }

  getSearchbarResults() { return this.searchResults.asObservable().pipe(); }

  slugify (str:any) { return str.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '')};

  setMetaTags(item:any) {
    //console.log('Setting meta tags...');
    const image1x = this.transformFilename(item.image);
    this.title.setTitle(item.metaTitle);
    this.meta.updateTag({name:'description', content: item.metaDescription});
    this.meta.updateTag({name:'keywords', content: item.keywords});

    this.meta.updateTag({name:'og:type', content: 'website'});
    this.meta.updateTag({name:'og:site_name', content: this.webpage.name});
    this.meta.updateTag({name:'og:url', content: `https://${this.webpage.name}`});
    this.meta.updateTag({name:'og:title', content: item.metaTitle});
    this.meta.updateTag({name:'og:description', content: item.metaDescription});
    this.meta.updateTag({name:'og:image', content: `https://${this.webpage.name}/assets/images/${item.image}`}); // Recommend 1200×628

    this.meta.updateTag({name:'twitter:card', content: "summary_large_image"});
    this.meta.updateTag({name:'twitter:url', content: `https://${this.webpage.name}` });
    this.meta.updateTag({name:'twitter:title', content: item.metaTitle });
    this.meta.updateTag({name:'twitter:description', content: item.metaDescription});
    this.meta.updateTag({name:'twitter:image', content: `https://${this.webpage.name}/assets/images/${image1x}`});
  }
}