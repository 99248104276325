import { Component, Input } from '@angular/core';
import { TextService } from 'src/services/text.service';


@Component({
  selector: 'related-articles',
  templateUrl: './related-articles.component.html',
  styleUrls: ['./related-articles.component.scss'],
  animations: [ ]
})
export class RelatedArticlesComponent {

  @Input() relatedItems: any;

  constructor(public text: TextService) { }

}
