<div class="top-header-space"></div>
<ion-content>
  <div class="h1-container">
    <h1 class="category-name">{{category?.name}}</h1>
  </div>

    <ion-list class="default-list" *ngIf="searchbarInputLength === 0">
      <ion-row>
        <ion-col role="listitem" *ngFor="let item of items | slice:0:itemsPerPage" size-xs="12" size-sm="6" size-md="4" size-lg="4">
          <div class="item-container default-zoom-color zoom-effect" [routerLink]="'/' + item?.path" [state]="{data: item}" [attr.aria-label]="'Open ' + item?.name" role="button" tabindex="0">
              <div class="default-image-container">
                <ion-skeleton-text aria-hidden="true" [ngClass]="{'hidden': item.loaded}" [animated]="true"></ion-skeleton-text>
                <ion-img alt="{{item?.title}}" src="{{item?.img1x}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
              </div>
            <span class="item-name" [ngClass]="{'fadeIn': item.loaded}">{{item.title}}</span>
            <span class="item-extract">{{item.extract}}</span>
          </div>
        </ion-col>
      </ion-row>
      <ion-infinite-scroll [disabled]="isInfiniteScollDisabled" threshold="15%" (ionInfinite)="loadMoreData($event)">
        <ion-infinite-scroll-content loadingSpinner="bubbles"></ion-infinite-scroll-content>
      </ion-infinite-scroll>

      <div class="description">{{category?.description}}</div>
    </ion-list>

    <searchbar-items-list [searchbarItems]="searchbarItems" [searchbarInputLength]="searchbarInputLength"></searchbar-items-list>
    <app-footer></app-footer>

</ion-content>
