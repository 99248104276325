// privacy-policy-popup.component.ts
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TextService } from 'src/services/text.service';

@Component({
  selector: 'app-privacy-policy-popup',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {

  showPopup: boolean = true;
  isBrowser: any;

  constructor(@Inject(PLATFORM_ID) platformId: Object, public text: TextService) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  dismiss() {
    if (this.isBrowser) {
      this.showPopup = false;
      localStorage.setItem(this.text.privacyPolicyPopup.variableName, 'true');
    }
  }
  ngOnInit() {
    if (this.isBrowser) {
      this.showPopup = !localStorage.getItem(this.text.privacyPolicyPopup.variableName);
    }
  }
}