import { Component } from '@angular/core';
import { TextService } from 'src/services/text.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [ ]
})
export class FooterComponent {

  constructor(public text: TextService) { }

}
