import { Component, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, RouteReuseStrategy } from '@angular/router';
import { WebpageService } from '../../services/web.service';
import { PreloadService } from '../../services/preload.service';
import { Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-article',
  templateUrl: './article.page.html',
  styleUrls: ['./article.page.scss'],
})
export class ArticlePage {

  @ViewChildren('productRef', { read: ElementRef }) productRefs: QueryList<ElementRef> | any;

  searchbarResults$: Subscription | undefined;
  webpage: any;
  article: any;
  category: any;
  searchbarItems: any[] = [];
  searchbarInputLength = 0;
  breadcrumbs: any[] = [];
  relatedItems: any;
  product: any;
  productNames: any;
  avatar: any;
  images: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private webpageService: WebpageService,
    private preloadService: PreloadService,
    public platform: Platform,
    private routeReuseStrategy: RouteReuseStrategy
  ) {

    this.routeReuseStrategy.shouldReuseRoute = () => false;

    this.route.url.subscribe(async segments => {
      this.webpage = await this.webpageService.getWebpageData();
      if (this.router.getCurrentNavigation()?.extras.state?.['item']) {
        let child = this.router.getCurrentNavigation()?.extras.state?.['item'];
        this.article = await this.webpageService.getItem(child.slug, `articles`);
        this.avatar = `/assets/avatars/${this.article.author.slug}.webp`;
        this.article.img = `/assets/images/${this.article.image}`;
        this.preloadService.preloadImage(this.article.img);
        this.preloadService.preloadImage(this.avatar);

        if (this.article) {
          this.getRelatedArticles();
          this.webpageService.setMetaTags(this.article);
          this.webpageService.initializeSearchbar();
          this.webpageService.clearSearchbarInput();
        }
        else {
          this.router.navigateByUrl('/');
        }
      }
      else {
        const itemSlug:any = this.route.snapshot.paramMap.get('itemSlug');
        this.article = await this.webpageService.getItem(itemSlug, `articles`);
        this.article.img = `assets/images/${this.article.image}`;
        this.avatar = `/assets/avatars/${this.article.author.slug}.webp`;
        this.preloadService.preloadImage(this.article.img);
        this.preloadService.preloadImage(this.avatar);

        if (this.article) {
          this.getRelatedArticles();
          this.webpageService.setMetaTags(this.article);
          this.webpageService.initializeSearchbar();
          this.webpageService.clearSearchbarInput();
        }
        else {
          this.router.navigateByUrl('/');
        }
      }
    });
  }

  ionViewDidEnter() {
    this.webpageService.clearSearchbarInput();
    this.searchbarResults$ = this.webpageService.getSearchbarResults().subscribe(results => {
      this.searchbarItems = results.items;
      this.searchbarItems.forEach((searchbarItem:any) => {
        let image = this.webpageService.transformFilename(searchbarItem.i);
        searchbarItem.img1x = `assets/images/${image}`;
      });
      this.searchbarInputLength = results.searchbarInputLength;
    });
  }

  ionViewWillLeave() {
    if (this.searchbarInputLength > 0 ) { this.webpageService.triggerClear(); }
    this.searchbarResults$?.unsubscribe();
  }

  scrollToElement(subheadingRefName: string) {
    const elementToScroll = this.productRefs.find((ref:any) => ref.nativeElement.id === subheadingRefName);
    if (elementToScroll) {
      elementToScroll.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  openLink(url: string): void { window.open(url, '_blank', 'noopener,noreferrer'); }

  getRelatedArticles() {
    this.relatedItems = this.article.relatedArticles;
    this.relatedItems.forEach((item:any) => {
      let image = this.webpageService.transformFilename(item.image);
      item.img1x = `assets/images/${image}`;
    });
  }

}
