<div class="top-header-space"></div>

<ion-content>

  <div class="h1-container"> <h1>{{text.privacyPolicy.pageTitle}}</h1> </div>

  <ion-list class="default-list" role="list" *ngIf="searchbarInputLength === 0">

    <article>
      <ng-container *ngFor="let section of privacyPolicy.sections; let i = index">
        <div class="contents" *ngIf="section.subheading" (click)="scrollToElement(section.subheadingRef)">
          <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon> {{section.subheading}}
        </div>
      </ng-container>
      <div class="introduction" *ngIf="privacyPolicy?.introduction" [innerHTML]="privacyPolicy.introduction"></div>
      <ng-container *ngFor="let section of privacyPolicy.sections; let i = index">
        <div id="{{section.subheadingRef}}" #subheadingRef *ngIf="section.subheading">
          <h2>{{section.subheading}}</h2>
        </div>
        <div *ngIf="section.section" [innerHTML]="section.section"></div>
      </ng-container>
    </article>

  </ion-list>

  <searchbar-items-list [searchbarItems]="searchbarItems" [searchbarInputLength]="searchbarInputLength"></searchbar-items-list>
  <app-footer></app-footer>
</ion-content>
