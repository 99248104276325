<div class="container" *ngIf="showPopup" role="dialog" aria-live="polite" aria-label="cookieconsent">
    <span>
        {{text.privacyPolicyPopup.slogan}}
        <a routerLink="{{'/' + text.routes.privacyPolicy}}" aria-label="learn more about cookies" role="button" tabindex="0"
          rel="noopener noreferrer nofollow">Learn more</a>
    </span>
    <div>
        <ion-button color="warning" (click)="dismiss()"  expand="block" fill="solid">
            OK
        </ion-button>
    </div>
</div>