import { Component } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private swUpdate: SwUpdate, private router: Router) {
    this.checkForUpdates();
  }

  private checkForUpdates(): void {
    //console.log('Checking for updates...');
    if (this.swUpdate.isEnabled) {
      //console.log('Service Worker is enabled');
      this.swUpdate.versionUpdates.pipe(
        filter((evt:any): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt:any) => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
      })),
     ).subscribe((event:any) => {
        console.log('Current ', event.currentVersion, 'available ', event.latestVersion);
        this.swUpdate.activateUpdate().then(() => this.reloadPage());
      });
    } else {
      //console.log('Service Worker is not enabled');
    }
  }

  private reloadPage(): void {
    //console.log('Reloading page...');
    this.router.navigate([this.router.url]).then(() => {
      console.log('Page reload initiated');
      window.location.reload();
    });
  }

}
