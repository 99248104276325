import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { HomePage } from '../pages/home/home.page';
import { ArticlePage } from '../pages/article/article.page';
import { ArticlesListPage } from '../pages/articles-list/articles-list.page';
import { PrivacyPolicyPage } from '../pages/privacy-policy/privacy-policy.page';
import { ContactPage } from '../pages/contact/contact.page';
import { TextService } from '../services/text.service';

@NgModule({
  imports: [
    RouterModule.forRoot([], {
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(
    private router: Router,
    private textService: TextService
  ) {
    const routes: Routes = [
      { path: this.textService.routes.contact, component: ContactPage },
      { path: this.textService.routes.privacyPolicy, component: PrivacyPolicyPage },
      { path: this.textService.routes.electronics, component: ArticlesListPage },
      { path: this.textService.routes.home, component: ArticlesListPage },
      { path: this.textService.routes.sportsTravel, component: ArticlesListPage },
      { path: this.textService.routes.gardenTools, component: ArticlesListPage },
      { path: this.textService.routes.healthWellness, component: ArticlesListPage },
      { path: this.textService.routes.reviewsItemSlug, component: ArticlePage },
      { path: '', component: HomePage },
      { path: '**', redirectTo: '', pathMatch: 'full' }
    ];
    this.router.resetConfig(routes);
  }
}
