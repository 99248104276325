<div class="top-header-space"></div>

<ion-content>
  <article *ngIf="article && searchbarInputLength === 0">
    <ion-list class="default-list" role="list">
      <div role="listitem" class="breadcrumbs">
        <span *ngFor="let breadcrumb of article?.breadcrumbs; let last = last">
          <a rel="nofollow" [routerLink]="'/' + breadcrumb?.path" [state]="{data: breadcrumb, breadcrumbs: breadcrumbs, breadcrumbsNav: true}" >
            <small>{{ breadcrumb?.name }}</small>
            <small *ngIf="!last"> > </small>
          </a>
        </span>
      </div>
      <div role="listitem">
        <h1 *ngIf="article?.title">{{article.title}}</h1>
      </div>
      <div *ngIf="article?.author" role="listitem" class="author-container">
        <div class="avatar-author">
          <ion-img alt="{{article?.author?.name}}" src="{{avatar}}"></ion-img>
          <div class="author-name">{{ article?.author?.name }}</div>
        </div>
        <time class="date-container" [attr.datetime]="article.createdAt | date:'yyyy-MM-ddTHH:mm:ss.sss'+'Z'">{{ article.createdAt | date:'MM/dd/yyyy' }}</time>
      </div>
      <div role="listitem" class="default-image-container">
        <ion-skeleton-text aria-hidden="true" [ngClass]="{'hidden': article.loaded}" [animated]="true"></ion-skeleton-text>
       <!-- <img loading="lazy" src="{{article?.img}}" [srcset]="srcset" (load)="article.loaded = true" alt="{{article?.imageAlt}}"/>-->
        <ion-img alt="{{article?.imageAlt}}" src="{{article?.img}}" (ionImgDidLoad)="article.loaded = true"></ion-img>
      </div>

      <div role="listitem" class="contents-container">
        <ng-container *ngFor="let product of article.products; let i = index">
          <div class="contents" *ngIf="product.shortTitle" (click)="scrollToElement(product.productRef)">
            <ion-icon class="icon" src="assets/icons-custom/caret-forward.svg"></ion-icon>
            <span *ngIf="article.type?.numbers">{{product.rank}}.</span> {{product.shortTitle}}
          </div>
        </ng-container>
      </div>
      <div role="listitem" class="article-introduction" *ngIf="article?.introduction" [innerHTML]="article.introduction"></div>

      <ng-container *ngFor="let product of article.products; let i = index">
        <div class="product-title" role="listitem" id="{{product.productRef}}" #productRef *ngIf="product.shortTitle">
          <span *ngIf="article.type?.numbers" class="rank">{{product.rank}}</span><h2>{{product.shortTitle}}</h2>
        </div>
        <ion-row role="listitem">
          <ion-col size-xs="12" size-sm="6">
            <div class="products-image-container">
              <a href="{{product.DetailPageURL}}" role="button" target='_blank' rel='nofollow'>
                <ion-skeleton-text [animated]="true" [ngClass]="{'hidden': product?.loaded}"></ion-skeleton-text>
                <ion-img [ngClass]="{'fadeIn': product.loaded}" src="{{product.Images[product.selectedImageIndexLeft]}}" alt="{{product?.shortTitle}}" (ionImgDidLoad)="product.loaded = true"></ion-img>
              </a>
            </div>
          </ion-col>
          <ion-col size-xs="12" size-sm="6">
            <div class="products-image-container">
              <a href="{{product.DetailPageURL}}" role="button" target='_blank' rel='nofollow'>
                <ion-skeleton-text [animated]="true" [ngClass]="{'hidden': product?.loaded}"></ion-skeleton-text>
                <ion-img [ngClass]="{'fadeIn': product.loaded}"  role="img" alt="{{product?.shortTitle}}" src="{{product.Images[product.selectedImageIndexRight]}}" (ionImgDidLoad)="product.loaded = true"></ion-img>
              </a>
            </div>
          </ion-col>
        </ion-row>
        <div class="underimage-title" role="listitem">{{product.shortTitle}}</div>
        <div class="price-button-container" role="listitem">
          <ion-button (click)="openLink(product.DetailPageURL)" fill="solid">
            <ion-icon src="assets/icons-custom/eye.svg"></ion-icon> {{product.DisplayPrice}} | AMAZON
          </ion-button>
        </div>
        <div role="listitem" *ngIf="product.review" [innerHTML]="product.review"></div>

       <ion-grid role="listitem">
          <ion-row>
            <ion-col size="12" size-sm="6" class="pros">
              <h2>{{article?.text?.pros}}</h2>
              <ul>
                <li *ngFor="let pro of product.pros">
                  <span><ion-icon src="assets/icons-custom/add-circle-outline.svg"></ion-icon></span>
                  <span>{{ pro }}</span>
                </li>
              </ul>
            </ion-col>
            <ion-col size="12" size-sm="6" class="cons">
              <h2>{{article?.text?.cons}}</h2>
              <ul>
                <li *ngFor="let con of product.cons">
                  <span><ion-icon src="assets/icons-custom/remove-circle-outline.svg"></ion-icon></span>
                  <span>{{ con }}</span>
                </li>
              </ul>
            </ion-col>
          </ion-row>
        </ion-grid>

        <div role="listitem" *ngIf="article.tips[i]" class="tip-box">
          <div class="tip-subheading">{{article?.text?.goodAdvices}}: {{article.categoryName}}</div>
          <div class="tip-heading">
            <h3>{{article?.text?.tip}} #{{i + 1}}</h3>
          </div>
          <p>{{article.tips[i]}}</p>
        </div>
      </ng-container>

      <ng-container *ngFor="let item of article.afterReviews; let i = index">
        <div class="product-title" role="listitem" *ngIf="item.title">
          <h2>{{item.title}}</h2>
        </div>
        <div *ngIf="item.section" [innerHTML]="item.section"></div>
      </ng-container>

      <share-buttons role="listitem" [webpageCDN]="webpage?.cdn" [item]="article"></share-buttons>

      <related-articles role="listitem" [relatedItems]="relatedItems" ></related-articles>

    </ion-list>

  </article>

  <searchbar-items-list [searchbarItems]="searchbarItems" [searchbarInputLength]="searchbarInputLength"></searchbar-items-list>
  <app-footer *ngIf="article"></app-footer>

</ion-content>
