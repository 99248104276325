import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextService {

  public routes = {
    contact: 'kontakt',
    privacyPolicy: 'datenschutzerklärung',
    electronics: 'elektronik',
    home: 'heim',
    sportsTravel: 'sport-reisen',
    gardenTools: 'garten-werkzeuge',
    healthWellness: 'gesundheit-wellness',
    // Do not change part /:itemSlug
    reviewsItemSlug: 'bewertungen/:itemSlug',
  }

  public topheader = {
    websiteName: 'TOP*REVIEWS',
    placeholderText: 'Suche...',
    arialabel: 'Zur Startseite gehen',
    ariaButtonSearch: 'Suchknopf',
    ariaButtonCloseSearch: 'Suchknopf schließen',
    ariaButtonCloseMenu: 'Menüknopf schließen',
    ariaButtonMenu: 'Menüknopf',
  }

  public relatedArticles = {
    subheading: 'LESEN SIE AUCH',
    ariaLabel: 'Verwandter Artikel: '
  }

  public home = {
    subheadingSectionA: 'STARTSEITE',
    subheadingSectionB: 'ELEKTRONIK',
    subheadingSectionC: 'GARTEN & WERKZEUGE',
    subheadingSectionD: 'SPORT & REISEN',
    subheadingSectionE: 'GESUNDHEIT & WELLNESS',
    buttonTextSectionA: 'ALLE STARTSEITE DURCHSUCHEN',
    buttonTextSectionB: 'ALLE ELEKTRONIK DURCHSUCHEN',
    buttonTextSectionC: 'ALLE GARTEN & WERKZEUGE DURCHSUCHEN',
    buttonTextSectionD: 'ALLE SPORT & REISEN DURCHSUCHEN',
    buttonTextSectionE: 'ALLE GESUNDHEIT & WELLNESS DURCHSUCHEN',
    ariaLabel: 'Öffnen '
  }

  public contact = {
    messageError: "Fehler! Etwas ist schief gelaufen!",
    messageSuccess: "Vielen Dank! Ihre Nachricht wurde erfolgreich gesendet. Wir werden uns bald bei Ihnen melden.",
    pageTitle: "Kontaktformular",
    submit: "Absenden",
    messageLabel: "Nachricht",
    messagePlaceholder: "Ihre Nachricht...",
    nameLabel: "Name",
    namePlaceholder: "Name...",
    emailLabel: "E-Mail",
    emailPlaceholder: "E-Mail eingeben..."
  }

  public footer = {
    contact: 'Kontakt',
    copyrights: "Urheberrecht © 2024 bestetopprodukte.de. Alle Rechte vorbehalten.",
    label: "Fußzeilen-Navigation",
    privacyPolicy: 'Datenschutzerklärung'
  }

  public privacyPolicyPopup = {
    slogan: "Diese Website verwendet Cookies, um sicherzustellen, dass Sie die beste Erfahrung auf unserer Website machen.",
    variableName: "bestetopproduktePrivacyPolicy"
  }

  public privacyPolicy = {
    pageTitle: "Datenschutzerklärung",
    contents: "Inhalt",
    introduction: "Gültig ab: 20.05.2024. Willkommen bei bestetopprodukte.de. Diese Datenschutzerklärung beschreibt, wie wir Ihre Informationen sammeln, verwenden und verarbeiten, wenn Sie unsere Website besuchen und nutzen. Durch die Nutzung von bestetopprodukte.de stimmen Sie der Erfassung und Nutzung von Informationen gemäß dieser Richtlinie zu.",
    sections: [
      {
        "subheadingRef": "subheadingRef1",
        "subheading": "Erhebung und Nutzung von Informationen",
        "section": "Google Analytics: Wir verwenden Google Analytics, um zu verstehen, wie unsere Website genutzt wird, um die Benutzererfahrung zu verbessern. Google Analytics sammelt Informationen darüber, wie oft Benutzer unsere Website besuchen, welche Seiten sie besuchen und welche anderen Websites sie vor dem Besuch unserer Website genutzt haben. Die gesammelten Informationen werden nur verwendet, um unsere Website und Dienstleistungen zu verbessern. Amazon-Partnerprogramm: Als Amazon-Partner verdienen wir an qualifizierten Käufen. Wenn Sie auf einen Amazon-Produktlink auf unserer Website klicken, kann Amazon Informationen über Ihren Besuch sammeln, um Verweise zu verfolgen."
      },
      {
        "subheadingRef": "subheadingRef2",
        "subheading": "Wie wir Informationen verwenden",
        "section": "Die von uns gesammelten Informationen werden wie folgt verwendet: Um unsere Website und Dienstleistungen zu verbessern, indem wir verstehen, wie sie genutzt werden. Zur Überwachung der Nutzung unserer Website. Zur Erkennung, Verhinderung und Behebung technischer Probleme. Um Verweise zu verfolgen und Provisionen über das Amazon-Partnerprogramm zu verdienen."
      },
      {
        "subheadingRef": "subheadingRef3",
        "subheading": "Weitergabe Ihrer Informationen",
        "section": "Wir geben Ihre persönlich identifizierbaren Informationen nicht an Dritte weiter, es sei denn, dies ist gesetzlich vorgeschrieben. Wir können generische, aggregierte demografische Informationen, die nicht mit persönlichen Identifikationsinformationen verknüpft sind, mit unseren Geschäftspartnern, vertrauenswürdigen Partnern und Werbetreibenden für die oben genannten Zwecke teilen."
      },
      {
        "subheadingRef": "subheadingRef4",
        "subheading": "Ihre Rechte",
        "section": "Sie haben das Recht, jederzeit auf Ihre persönlichen Informationen zuzugreifen, sie zu bearbeiten oder zu löschen. Wenn Sie diese Rechte ausüben möchten, kontaktieren Sie uns bitte unter kontakt@bestetopprodukte.de"
      },
      {
        "subheadingRef": "subheadingRef5",
        "subheading": "Datensicherheit",
        "section": "Wir schätzen Ihr Vertrauen in die Bereitstellung Ihrer persönlichen Informationen und bemühen uns, kommerziell akzeptable Mittel zum Schutz dieser Informationen zu verwenden. Keine Methode der Übertragung über das Internet oder der elektronischen Speicherung ist jedoch zu 100% sicher und zuverlässig, und wir können ihre absolute Sicherheit nicht garantieren."
      },
      {
        "subheadingRef": "subheadingRef6",
        "subheading": "Datenschutz von Kindern",
        "section": "Unsere Dienstleistungen richten sich nicht an Personen unter 13 Jahren. Wir sammeln nicht wissentlich persönlich identifizierbare Informationen von Kindern unter 13 Jahren. Wenn Sie ein Elternteil oder Erziehungsberechtigter sind und feststellen, dass Ihr Kind uns persönliche Informationen zur Verfügung gestellt hat, kontaktieren Sie uns bitte, damit wir die notwendigen Maßnahmen ergreifen können."
      },
      {
        "subheadingRef": "subheadingRef7",
        "subheading": "Änderungen dieser Datenschutzerklärung",
        "section": "Wir können unsere Datenschutzerklärung von Zeit zu Zeit aktualisieren. Wir empfehlen Ihnen daher, diese Seite regelmäßig auf Änderungen zu überprüfen. Wir werden Sie über Änderungen informieren, indem wir die neue Datenschutzerklärung auf dieser Seite veröffentlichen. Diese Änderungen treten sofort nach ihrer Veröffentlichung auf dieser Seite in Kraft."
      },
      {
        "subheadingRef": "subheadingRef8",
        "subheading": "Kontaktieren Sie uns",
        "section": "Wenn Sie Fragen oder Anregungen zu unserer Datenschutzerklärung haben, zögern Sie nicht, uns unter kontakt@bestetopprodukte.de zu kontaktieren."
      }
    ]
  }


  constructor() {

  }

}