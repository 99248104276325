<div>
  <h2 class="ra-heading">{{text.relatedArticles.subheading}}</h2>
</div>
<ion-row>
    <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="3" *ngFor="let relatedItem of relatedItems" role="list">
      <div role="listitem" class="ra-item">
        <a [routerLink]="'/' + relatedItem.path" [state]="{data: relatedItem}"  [attr.aria-label]="text.relatedArticles.ariaLabel + relatedItem?.title" tabindex="0" role="button">
          <ion-skeleton-text [ngClass]="{'hidden': relatedItem?.loaded}" [animated]="true"></ion-skeleton-text>
          <ion-img role="img" alt="{{relatedItem?.title}}" src="{{relatedItem?.img1x}}" [ngClass]="{'fadeIn': relatedItem.loaded}" (ionImgDidLoad)="relatedItem.loaded = true"></ion-img>
        </a>
        <span class="ra-title">{{relatedItem.title}}</span>
      </div>
    </ion-col>
</ion-row>