import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule } from '@ionic/angular'; // usuniete IonicRouteStrategy
import { HttpClientModule } from '@angular/common/http';
import { ShufflePipe } from '../shared/shuffle.pipe';
import { HomePage } from '../pages/home/home.page';
import { ArticlePage } from '../pages/article/article.page';
import { ArticlesListPage } from '../pages/articles-list/articles-list.page';
import { ContactPage } from '../pages/contact/contact.page';
import { PrivacyPolicyPage } from '../pages/privacy-policy/privacy-policy.page';
import { TopHeaderComponent } from 'src/components/top-header/top-header.component';
import { FooterComponent } from 'src/components/footer/footer.component';
import { RelatedArticlesComponent } from 'src/components/related-articles/related-articles.component';
import { SearchbarItemsListComponent } from 'src/components/searchbar-items-list/searchbar-items-list.component';
import { ShareButtonsComponent } from 'src/components/share-buttons/share-buttons.component';
import { PrivacyPolicyComponent } from 'src/components/privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePage,
    ArticlePage,
    ArticlesListPage,
    ContactPage,
    PrivacyPolicyPage,
    FooterComponent,
    TopHeaderComponent,
    RelatedArticlesComponent,
    SearchbarItemsListComponent,
    ShareButtonsComponent,
    PrivacyPolicyComponent,
    ShufflePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    IonicModule.forRoot({animated: true}),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [],
  //providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }], // dodane
  bootstrap: [AppComponent]
})
export class AppModule { }
