import { Component, Input} from '@angular/core';
import { WebpageService } from 'src/services/web.service';

@Component({
  selector: 'searchbar-items-list',
  templateUrl: './searchbar-items-list.component.html',
  styleUrls: ['./searchbar-items-list.component.scss'],
  animations: [ ]
})
export class SearchbarItemsListComponent {

  @Input() searchbarItems: any[] = [];
  @Input() searchbarInputLength: number = 0;

  constructor(private webpageService: WebpageService) { }

  async openSearchbarItem(item: any) { await this.webpageService.openSearchbarItem(item); }

}
