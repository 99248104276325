<div class="top-header-space"></div>

<ion-content>

  <div class="h1-container"> <h1>{{text.contact.pageTitle}}</h1> </div>

  <ion-list class="default-list" role="list" *ngIf="searchbarInputLength === 0">
    <form [formGroup]="contactForm" (ngSubmit)="onSubmitContactForm()">

      <ion-input
        type="text"
        id="name"
        formControlName="name"
        label="{{text.contact.nameLabel}}"
        labelPlacement="stacked"
        fill="outline"
        placeholder="{{text.contact.namePlaceholder}}">
      </ion-input>

      <ion-input
        type="text"
        id="email"
        formControlName="email"
        label="{{text.contact.emailLabel}}"
        labelPlacement="stacked"
        fill="outline"
        placeholder="{{text.contact.emailPlaceholder}}">
      </ion-input>

      <ion-input class="input-message"
        type="text"
        id="message"
        formControlName="message"
        label="{{text.contact.messageLabel}}"
        labelPlacement="stacked"
        fill="outline"
        placeholder="{{text.contact.messagePlaceholder}}">
      </ion-input>

      <div class="message-success" *ngIf="messageSuccess">{{messageSuccess}}</div>

      <div class="message-error" *ngIf="messageError">{{messageError}}</div>

      <ion-button color="success" type="submit" expand="block" [disabled]="!contactForm.valid" >{{text.contact.submit}}</ion-button>

    </form>
  </ion-list>

  <searchbar-items-list [searchbarItems]="searchbarItems" [searchbarInputLength]="searchbarInputLength"></searchbar-items-list>
  <app-footer></app-footer>
</ion-content>
