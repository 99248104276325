<footer>
  <div>
    <nav class="footer-nav" [attr.aria-label]="text.footer.label">
      <ul>
        <li><a routerLink="{{'/' + text.routes.contact}}">{{text.footer.contact}}</a></li>
        <li><a routerLink="{{'/' + text.routes.privacyPolicy}}">{{text.footer.privacyPolicy}}</a></li>
      </ul>
    </nav>
    <span class="copyrights">{{text.footer.copyrights}}</span>
  </div>
</footer>